import {getFlatInterestRemaining, getPaymentSchedule, getRemainingPayments} from "./financeUtils";
import {NotificationManager} from "react-notifications";
import axios from "axios";
import {createSetDataFreshAction} from "./actions/dataActions";

export function getPartCustomerCost(part, customer, appDefaults) {
  if (part.cost && !part.cost_override) {
    return customer && (customer.parts_markup || customer.parts_markup === 0) ? (1 * part.cost + part.cost * (customer.parts_markup / 100)).toFixed(
      2
    ) : (1 * part.cost + part.cost * (appDefaults.default_parts_markup / 100)).toFixed(
      2
    )
  }
  return (1 * part.customer_cost || 0).toFixed(2);
}

export function getStatus(job, itemType) {
  const startField =
    itemType === "Job" ? "workshop_date" : "provisional_start_date";
  const approvedField =
    itemType === "Job" ? "approved" : "ready_for_customer_invoice";

  let status =
    new Date(job[startField]) < new Date() ? "In Progress" : "Not Started";

  if (itemType === "Job") {
    if (job.status === "RETURNED_FROM_WORKSHOP") {
      status = "RETURNED FROM WORKSHOP";
    } else if (job.status === "READY_FOR_COLLECTION") {
      status = "READY FOR COLLECTION";
    } else if (job.status === "COLLECTED") {
      status = "COLLECTED"
    }
  }

  const approved = !!job[approvedField];
  return {status, approved};
}

export function getPartCustomerEstimatedCost(part, customer, appDefaults) {
  if (part.estimated_cost && !part.cost_override) {
    return customer && (customer.parts_markup || customer.parts_markup === 0) ? (1 * part.estimated_cost + part.estimated_cost * (customer.parts_markup / 100)).toFixed(
      2
    ) : (1 * part.estimated_cost + part.estimated_cost * (appDefaults.default_parts_markup / 100)).toFixed(
      2
    )
  }
  return (1 * part.estimated_customer_cost || 0).toFixed(2);
}

export function getWorkItemCustomerCost(vehicle, supplier, newWorkItem, customer, lcv_job) {
  if (vehicle.type && vehicle.type.special_labour_rate) {
    newWorkItem.customer_labour_cost = (
      newWorkItem.labour_hours * vehicle.type.special_labour_rate
    ).toFixed(2);
  }
  if (customer && customer.labour_rate_markup) {
    newWorkItem.customer_labour_cost = (
      (1 * newWorkItem.customer_labour_cost) * (1 + (customer.labour_rate_markup / 100))
    ).toFixed(2);
  }

  if (supplier) {
    let supplier_labour_charge = lcv_job && supplier.lcv_rate ? supplier.lcv_rate : supplier.supplier_labour_charge
    if (supplier && supplier.labour_rate_affected_by_customer_markup) {
      newWorkItem.labour_cost = (
        (newWorkItem.labour_hours * supplier_labour_charge) * (1 + (customer.labour_rate_markup / 100))
      ).toFixed(2);
    } else if (supplier && supplier_labour_charge) {
      newWorkItem.labour_cost = (
        newWorkItem.labour_hours * supplier_labour_charge
      ).toFixed(2);
    }
    return newWorkItem;
  }
  else{
    return newWorkItem;
  }
}

export function getWorkItemEstimateCustomerCost(vehicle, supplier, newWorkItem, customer, defaultLabourQuoteRate) {
  if (vehicle.type && vehicle.type.special_labour_rate) {
    newWorkItem.estimated_customer_labour_cost = (
      newWorkItem.estimated_labour_hours *
      vehicle.type.special_labour_rate
    ).toFixed(2);
  }
  if (customer && customer.labour_rate_markup) {
    newWorkItem.estimated_customer_labour_cost = (
      (1 * newWorkItem.estimated_customer_labour_cost) * (1 + (customer.labour_rate_markup / 100))
    ).toFixed(2);
  }
  if (defaultLabourQuoteRate) {
    newWorkItem.estimated_labour_cost = (
      newWorkItem.estimated_labour_hours * defaultLabourQuoteRate
    ).toFixed(2);
  }
  if (customer && customer.labour_rate_markup) {
    newWorkItem.estimated_labour_cost = (
      (1 * newWorkItem.estimated_labour_cost) * (1 + (customer.labour_rate_markup / 100))
    ).toFixed(2)
  }
  return newWorkItem;
}

export function getQueryString(params) {
  let keys = Object.keys(params);

  if (params && keys.length) {
    keys.forEach(key => {
      if (typeof params[key] === "undefined" || params[key] === null) {
        delete params[key];
      }
    });
    return (
      "?" +
      Object.keys(params)
        .map(key => key + "=" + encodeURIComponent(params[key]))
        .join("&")
    );
  }
  return "";
}

export function formatDate(date) {
  let day = "" + date.getDate();
  let month = "" + (date.getMonth() + 1);
  let year = "" + date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function readableDate(date) {
  if (!date) return "";
  let dateObject = toDate(date);
  let dd = dateObject.getDate();
  let mm = dateObject.getMonth() + 1; //January is 0!

  let yyyy = dateObject.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  return dd + "/" + mm + "/" + yyyy;
}

const toDate = dateStr => {
  const [year, month, day] = dateStr.split("-");
  return new Date(year, month - 1, day);
};

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
}

export function sortRentals(rentals, startDate) {
  const monday = new Date(startDate);
  let day_rentals = [[], [], [], [], [], [], []];
  let day_totals = [0, 0, 0, 0, 0, 0, 0];
  for (var rental of rentals) {
    for (let i = 0; i < 7; i++) {
      var day = new Date(monday);
      day.setDate(monday.getDate() + i);
      if (
        new Date(rental.start_date) <= day &&
        day <= new Date(rental.finish_date)
      ) {
        day_totals[i] += rental.daily_cost;
        day_rentals[i].push(rental);
      }
    }
  }
  return {day_rentals: day_rentals, day_totals: day_totals};
}

export function getFirstDayOfMonth(selected) {
  let date = new Date(selected), y = date.getFullYear(), m = date.getMonth();
  return new Date(y, m, 1);
}

export function getLastDayOfMonth(selected) {
  let date = new Date(selected), y = date.getFullYear(), m = date.getMonth();
  return new Date(y, m + 1, 0);
}

export function getFirstDayOfWeek(selected) {
  let d = new Date(selected);
  let day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1);
  d.setDate(diff);

  return new Date(
    Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate())
  );
}

export function getLastDayOfWeek(start) {
  let finish_date = new Date(start);
  finish_date.setDate(finish_date.getDate() + 6);
  return finish_date;
}

export const getOutstandingBalance = (vehicle) => {
  let date;
  if (vehicle.finance_early_settlement_date) {
    date = new Date(vehicle.finance_early_settlement_date);
  } else {
    date = new Date(Date.now())
  }

  if (
    // !vehicle.finance_early_settlement_date &&
    (vehicle.end_date &&
      new Date(vehicle.end_date) > date
    ) || (
      vehicle.sp_capital_repayment_end_date && new Date(vehicle.sp_capital_repayment_end_date) > date
    )
  ) {
    let now = new Date(Date.now());
    let finalPayment = new Date(vehicle.end_date);
    if (vehicle.finance_type === "REGULAR" || vehicle.finance_type === 'LEASE_AGREEMENT') {
      const monthlies =
        Math.min(getMonthsBetween(date, finalPayment), vehicle.payment_term) *
        vehicle.monthly_payment;
      return monthlies.toFixed(2);
    } else if (vehicle.finance_type === "FIXED_MONTHLY_CAPITAL") {
      const paymentSchedule = getPaymentSchedule(vehicle);
      const remainingPayments = getRemainingPayments(paymentSchedule, date);
      const monthly_payment = vehicle.monthly_payment * 1;
      const balloon_payment = vehicle.balloon_payment * 1;

      const outstandingBalance = (
        remainingPayments.length * monthly_payment +
        getFlatInterestRemaining(vehicle, date, finalPayment) +
        (vehicle.finance_type === "STOCKING_PLAN" ? balloon_payment : 0)
      );

      return outstandingBalance ? outstandingBalance : 0;
    } else if (vehicle.finance_type === "STOCKING_PLAN") {
      const paymentSchedule = getPaymentSchedule(vehicle);
      const remainingPayments = getRemainingPayments(paymentSchedule, date);

      const remainingInterest = remainingPayments.reduce((prev, next) => ({interest_accrued: prev.interest_accrued + next.interest_accrued}), {interest_accrued: 0}).interest_accrued || 0
      const remainingCapital = remainingPayments.reduce((prev, next) => ({capital_payment: prev.capital_payment + next.capital_payment}), {capital_payment: 0}).capital_payment || 0

      let balloonValue = (vehicle.balloon_payment_date && new Date(vehicle.balloon_payment_date) < date)

      const outstandingBalance = remainingInterest + remainingCapital + vehicle.balloon_payment;

      return outstandingBalance ? Math.round(outstandingBalance * 100 + Number.EPSILON) / 100 : 0;
    }
  } else {
    return 0;
  }
};

export const getMonthsBetween = (a, b) => {
  if (!a || !b) {
    return 0;
  }
  let months;
  months = (b.getFullYear() - a.getFullYear()) * 12;
  months -= a.getMonth();
  months += b.getMonth();

  let today = a.getDate();
  let finalPaymentDay = b.getDate();

  if (today <= finalPaymentDay) {
    months += 1;
  }

  return months;
};

export const getFreePeriodMonths = (a, b) => {
  let dateA, dateB;
  if (!a || !b) {
    return 0;
  }
  try {
    dateA = new Date(a);
    dateB = new Date(b);
  } catch {
    return 0;
  }

  let months;
  months = (dateB.getFullYear() - dateA.getFullYear()) * 12;
  months -= dateA.getMonth();
  months += dateB.getMonth();

  let today = dateA.getDate();
  let finalPaymentDay = dateB.getDate();

  if (today <= finalPaymentDay) {
    months += 1;
  }

  months -= 1;

  return months > 0 ? months : 0;
}

export const getMonthsBetweenFormDates = (a, b) => {
  let dateA, dateB;
  if (!a || !b) {
    return 0;
  }
  try {
    dateA = new Date(a);
    dateB = new Date(b);
  } catch {
    return 0;
  }
  return getMonthsBetween(dateA, dateB)
}

const getDaysBetween = (a, b) => {
  // The number of milliseconds in all UTC days (no DST)
  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const start = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const end = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  // so it's safe to divide by 24 hours
  return (end - start) / oneDay;
};

export const getEstimatedValue = vehicle => {
  if (!vehicle.internal_sale_date) {
    let now = new Date(Date.now());
    return getEstimatedValueForDate(vehicle, now);
  } else {
    return getInternalSalePrice(vehicle);
  }
};

export const getEstimatedValueForDate = (vehicle, date) => {
  let purchaseDate = new Date(vehicle.depreciation_start_date);
  let days = getDaysBetween(purchaseDate, date);

  const daily_depreciation_rate =
    1 - Math.pow(1 - vehicle.annual_depreciation_rate / 100, 1 / 365);

  return vehicle.purchase_price * Math.pow(1 - daily_depreciation_rate, days);
};

export const getInternalSalePrice = vehicle => {
  if (vehicle.internal_sale_date) {
    const saleDate = new Date(vehicle.internal_sale_date);
    return getEstimatedValueForDate(vehicle, saleDate);
  } else {
    return 0;
  }
};

export const formatCurrency = num => {
  return (
    "£" + roundCurrency(num)
  );
};

export const roundCurrency = num => {
  return (
    parseFloat(num || 0).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  );
}

export const getHpInterestRate = vehicle => {
  if (
    vehicle.payment_term &&
    vehicle.monthly_payment &&
    vehicle.finance_amount_borrowed &&
      (vehicle.finance_type === "REGULAR" || vehicle.finance_type === "LEASE_AGREEMENT")
  ) {
    return (
      rate(
        1 * vehicle.payment_term,
        -1 * vehicle.monthly_payment,
        1 * vehicle.finance_amount_borrowed || 0
      ) *
      12 *
      100
    ).toFixed(5);
  } else {
    return parseFloat(vehicle.hp_interest_rate) || 0;
  }
};

export const rate = (nper, pmt, pv, fv, type, guess) => {
  // Sets default values for missing parameters
  fv = typeof fv !== "undefined" ? fv : 0;
  type = typeof type !== "undefined" ? type : 0;
  guess = typeof guess !== "undefined" ? guess : 0.1;

  // Sets the limits for possible guesses to any
  // number between 0% and 100%
  var lowLimit = 0;
  var highLimit = 1;

  // Defines a tolerance of up to +/- 0.00005% of pmt, to accept
  // the solution as valid.
  var tolerance = Math.abs(0.00000005 * pmt);

  // Tries at most 40 times to find a solution within the tolerance.
  for (var i = 0; i < 40; i++) {
    // Resets the balance to the original pv.
    var balance = pv;

    // Calculates the balance at the end of the loan, based
    // on loan conditions.
    for (var j = 0; j < nper; j++) {
      if (type == 0) {
        // Interests applied before payment
        balance = balance * (1 + guess) + pmt;
      } else {
        // Payments applied before insterests
        balance = (balance + pmt) * (1 + guess);
      }
    }

    // Returns the guess if balance is within tolerance.  If not, adjusts
    // the limits and starts with a new guess.
    if (Math.abs(balance + fv) < tolerance) {
      return guess;
    } else if (balance + fv > 0) {
      // Sets a new highLimit knowing that
      // the current guess was too big.
      highLimit = guess;
    } else {
      // Sets a new lowLimit knowing that
      // the current guess was too small.
      lowLimit = guess;
    }

    // Calculates the new guess.
    guess = (highLimit + lowLimit) / 2;
  }

  // Returns null if no acceptable result was found after 40 tries.
  return null;
};

export const getWorkTotals = (work, labourFieldName, partFieldName) => {
  return work.reduce(
    (sum, curr) => {
      return {
        [labourFieldName]: 1 * sum[labourFieldName] + 1 * curr[labourFieldName],
        [partFieldName]:
        1 * sum[partFieldName] +
        curr.parts.reduce((partsSum, currentPart) => {
          return 1 * partsSum + 1 * currentPart[partFieldName];
        }, 0)
      };
    },
    {[labourFieldName]: 0, [partFieldName]: 0}
  );
};
export function fixDates(item, dateFields){
  dateFields.forEach((field) => {
    if(item[field] === ""){
      item[field] = null;
    }
  })
  return item
}
export const fixEmptyDates = (job, dateFields) => {
    dateFields.forEach(function (date) {
      if (job[date] === "") {
        job[date] = null;
      }
    });
    let work_items = job.work_items;
    job.work_items = work_items.map(work => {
      let parts = work.parts;
      work.parts = parts.map(part => {
        if (part.date_ordered === "") {
          part.date_ordered = null;
        }
        if (part.backorder_expected_date === "") {
          part.backorder_expected_date = null;
        }
        return part;
      });
      return work;
    });
    return job;
};
export const addVat = (amount) => {
      return amount*1.2;
}
export const fixEmptyDepartment = (job) => {
    if (!job.department){
      job.department = {name: "Rentals Direct", id:1};
    }
    return job;
};

export const validateInvoiceNumber = (inv_no_to_test) => {
  const validText = ['warranty', 'cash sale', 'rd', 'nr']

  if (!inv_no_to_test || inv_no_to_test.length < 1) {
    return false;
  } else {
    let invoice_number = inv_no_to_test.toLowerCase();
    return validText.some(function (v) {
      return invoice_number.indexOf(v) >= 0;
    }) || !/[A-Za-z]+/.test(inv_no_to_test)
  }
}
export const validateBeforeApproval = (work, view) => {
    if (view === "invoice") {
      if (work.chargeable && validateInvoiceNumber(work.job_customer2_invoice_number)) {
        return true;
      } else if (
        !work.chargeable &&
        work.job_customer_invoice_number.length > 1
      ) {
        return true;
      }
    } else if (view === "po") {
      /*
        Not Warranty work -> check for workshop invoice number
        Warranty work and Approval needed -> check for approval received
        Warranty work and Not Approval needed -> do not check for anything
       */
      if (work.work_type) {
        if (!work.work_type.warranty_work) {
          if (validateInvoiceNumber(work.job_workshop_invoice_number) && work.job_workshop_payment_date) {
            return true;
          }
          else {
            NotificationManager.warning("Missing workshop invoice number")
          }
        }
        else if (work.work_type.warranty_work && work.work_type.approval_needed) {
          if (work.approval_received) {
            return true
          } else {
            NotificationManager.warning("Missing approval")
          }
        } else if (work.work_type.warranty_work && !work.work_type.approval_needed) {
          return true
        }
      }
      else{
        NotificationManager.warning("Work Type is required")
      }
    }
    return false;
};

export const onFileChange = (e, fileList, setFileList) =>{
  let files = e.target.files;
  let dataTransfer = new DataTransfer();
  if(fileList && fileList.length) {
    for (let i = 0; i < fileList.length; i++) {
      dataTransfer.items.add(fileList[i]);
    }
  }
  for(let i=0; i<files.length;i++){
    dataTransfer.items.add(files[i]);
  }
  setFileList(dataTransfer.files);
}
const upload = async function (file, res) {
    const url = res['url'];
    if (url) {
      if(file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'application/pdf'){
        NotificationManager.error("Not supported file format");
        return new Promise((resolve, reject) => {reject("Not supported File format")});
      }
      const urlToUse = url[file.type]

      const conf = {
        headers: {
          "Content-Type": file.type,
          "Content-Disposition": "inline"
        },
        method: "put",
        url: urlToUse,
        data: file
      }

      // axios(conf).then(uploadResponse => {
      //  if (uploadResponse.ok) {
      //    NotificationManager.success("Image uploaded successfully");
      //    return new Promise((resolve, reject) => {resolve(true)});
      //  } else {
      //    NotificationManager.error("Failed to upload the file to the signed URL");
      //    return new Promise((resolve, reject) => {reject("Failed to upload")});
      //  }
      // })

      //  SHOULD BE USING AXIOS
      const uploadResponse = await fetch(urlToUse, {
       method: "PUT",
       headers: {
         "Content-Type": file.type,
         "Content-Disposition": "inline",
       },
       body: file, // the file blob
      });
      if (uploadResponse.ok) {
         NotificationManager.success("Image uploaded successfully");
         return new Promise((resolve, reject) => {resolve(true)});
       } else {
         NotificationManager.error("Failed to upload the file to the signed URL");
         return new Promise((resolve, reject) => {reject("Failed to upload")});
       }

    }
    else{
      NotificationManager.warning("Upload url could not be found");
      return new Promise((resolve, reject) => {reject("No URL found")});
    }
}
export const triggerUpload = (files, jobId, selectedVehicle, setFileList, deleteImage, dataFresh, dispatch, type) => {
  if(selectedVehicle) {
    if (files) {
      const promises = []
      let urls = []
      for (let i=0;i<files.length; i++){
          urls.push(axios.get('/api/uploadimage?reg=' + selectedVehicle.registration + "&id=" + jobId+"&type="+type+"&file_type="+files[i].type))
      }
      Promise.all(urls).then(urls =>{
          urls.forEach((url, index) => {
              promises.push(upload(files[index], url.data).catch((err) => {
                  deleteImage(url.data['image'])
                  NotificationManager.error("Image did not successfully upload");
              }));
          });
          Promise.all(promises).then(res => {
              setFileList(undefined);
              dispatch(createSetDataFreshAction(dataFresh + 1));
          })
      });
    }
  }
  else{
    NotificationManager.error("No vehicle selected");
  }
}

export function hasApprovedWork(job, approveField){
  let return_data = false
  if(job){
    job.work_items.forEach(work => {
      if(work[approveField]){
        return_data = true;
      }
    })
  }
  return return_data;
}