import FilterHeading from "../../../Invoicing/FilterHeading";
import React from "react";
import FilterBlankHeading from "../../../Invoicing/FilterBlankHeading";

function ActiveHeadings({data, activeTab, vehicleStatus, filters, setPage, setFilters}) {
  const headings = [
      {
          label:"Registration",
          display: true
      },
      {
          label:"Fleet Number",
          display: activeTab === "fleet"
      },
      {
        label: "Owner",
        field: "owner",
        labelField: "owner__display_name",
        display: activeTab === "customer"
      },
      {
          label:"Make",
          field:"make",
          labelField:"make__name",
          display: true
      },
      {
          label:"Model",
          field:"model",
          labelField:"model__name",
          display: true
      },
      {
          label: "Variant",
          display: true
      },
      {
          label: "Key Number",
          field: "key",
          labelField: "key__key_number",
          display:true
      },
      {
          label:"Chassis No.",
          display:true
      },
      {
          label: "Tracker IMEI",
          display: true
      },
      {
          label:"Type",
          field:"type",
          labelField:"type__name",
          display:true
      },
      {
          label:"Remaining Capital",
          display:true
      },
      {
          label:"Depreciated Value",
          display:true
      },
      {
          label:"Tax Cost",
          display:true
      },
      {
          label:"Purchase Date",
          display:true
      }
  ];
  return (
      <tr>
          {headings.map(heading => {
              if(heading.display) {
                  if (!heading.field) {
                        return <th key={heading.label}>{heading.label}</th>;
                  }
                  if (heading.blank) {
                      return (
                          <FilterBlankHeading
                              key={heading.label}
                              heading={heading}
                              setFilters={f => {
                                  setPage(1);
                                  setFilters(f);
                              }}
                              filters={filters}
                          />
                      );
                  }
                  return (
                      <FilterHeading
                          key={heading.label}
                          heading={heading}
                          setFilters={f => {
                              setPage(1);
                              setFilters(f);
                          }}
                          filters={filters}
                          options={data.filter_list[heading.field]}
                      />
                  );
              }
          })}
      </tr>
  )
}

export default ActiveHeadings;
