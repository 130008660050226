import {Columns} from "react-bulma-components";
import React from "react";


export default function Figure(props) {
  return (
    <Columns.Column>
      {props.label+": "}
      {props.loaded && props.value != null
        ? props.round ? props.value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        : props.value
        : "Loading..."}
    </Columns.Column>
  );
}