import React from "react";
import {Box, Heading, Modal, Section} from "react-bulma-components";
import Spinner from "react-spinner-material";
import LoadingSpinner from "./Spinner";



export default function LoadingModal({open, setOpen}){

    return (
        <Modal
        closeOnBlur
        show={open}
        onClose={() => {
            setOpen(false);
        }}
      >
        <Modal.Content>
          <Section style={{backgroundColor: "white"}}>
            <LoadingSpinner/>
          </Section>
        </Modal.Content>
      </Modal>
    )




}