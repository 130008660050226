import React, {useState} from "react";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {getDataFresh} from "../../state/selectors";
import {NotificationManager} from "react-notifications";
import useEditableTableCostCell from "../../hooks/useEditableTableCostCell";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import useSafeSave from "../../hooks/useSafeSave";
import FAIcon from "../Icon/FAIcon";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import { Icon } from "react-bulma-components";
import DeleteWarningModal from "../Modal/DeleteWarningModal";
import useInlineEditButton from "../../hooks/useInlineEditButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";

const AsyncTypeahead = asyncContainer(Typeahead);

function TransactionRow({
                          transaction,
                          index,
                          onTransactionUpdate,
                          endpoint,
                          onSelectCheck,
                          selected,
                          clearSelected,
                          editDisabled,
                          transactionCategories,
                          costCentres,
                          contact_type
                        }) {
  const dateFields = ["invoice_date", "payment_date", "date"];
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [lastEdited, setLastEdited] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  let dataFresh = useSelector(getDataFresh);

  const categoryFields = transactionCategories;
  const categoryObjects = categoryFields.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});

  const costCentreObjects = costCentres.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});



  const handleCategoryUpdate = e => {
    const toUpdate = {
      target: {
        name: "category",
        value: categoryObjects[e.target.value]
      }
    };
    onChange(toUpdate)
  };

  const handleCostCentreUpdate = e => {
    const toUpdate = {
      target: {
        name: "cost_centre",
        value: e.target.value ? costCentreObjects[e.target.value] : null
      }
    };
    onChange(toUpdate)
  };

  const fixEmptyDates = transactionData => {
    dateFields.forEach(function (date) {
      if (transactionData[date] === "") {
        transactionData[date] = null;
      }
    });
    return transactionData;
  };
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [, setTypeahead] = useState();

  const handleDelete = id => {
    const endpoint = "/api/transactions/" + id;
    const conf = {method: "delete", url: endpoint};
    axios(conf).then(response =>
      dispatch(createSetDataFreshAction(dataFresh + 1))
    );
  };

  const handleSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=${contact_type}&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  };

  const handleTransactionContactSelect = contact => {
    let newTransaction = {...transaction, contact: contact[0]};
    makeDirty();
    setLastEdited(new Date());
    onTransactionUpdate(index, newTransaction);
  };

  const submitUpdate = approval => {
    transaction.approved = !!approval;
    const url = endpoint + "transactions/update/" + transaction.id;
    const data = fixEmptyDates(transaction);
    const conf = {
      method: "put",
      data,
      url
    };

    return axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const onTransactionApprove = (e, approval) => {
    e.stopPropagation();
    if (transaction.payment_date && transaction.invoice_number && (transaction.type === 'IN' ? /^\d+$/.test(transaction.invoice_number) : true) || !approval) {
      submitUpdate(approval).then(clearSelected);
    } else {
      NotificationManager.warning(
        "You must specify a payment date and invoice number before approving!",
        "Payment Date Required",
        10000
      );
    }
  };

  const onSave = e => {
    return submitUpdate();
  };

  const checkUpdated = () => {
    return axios.get(endpoint + "transactions/" + transaction.id).then(newTransaction => {
      return newTransaction.data.last_saved === transaction.last_saved;
    });
  };

  const [makeDirty, safeSave] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newTransaction = {...transaction};
    newTransaction[e.target.name] = e.target.value;
    if (e.target.name === 'amount') {
      newTransaction.vat = Math.round((e.target.value * 0.2) * 100) / 100
    }
    if (e.target.name === 'invoice_amount') {
      newTransaction.invoice_vat = Math.round((e.target.value * 0.2) * 100) / 100
    }
    onTransactionUpdate(index, newTransaction);
  };

  const onCheck = e => {
    e.stopPropagation();
    onSelectCheck(transaction);
  };

  const onStockCheck = e => {
    e.stopPropagation();
    transaction.from_stock = !transaction.from_stock;
    const url = endpoint + "transactions/update/" + transaction.id;
    const data = fixEmptyDates(transaction);
    const conf = {
      method: "put",
      data,
      url
    };

    return axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  return (
    <tr
      className="small-row-black"
      key={transaction.id}
    >
      <td>{transaction.id}</td>
      {useEditableTableDateCell({
        propName: "date",
        value: transaction.date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        disabled: editDisabled,
        inlineEdit: false
      })}
      {useEditableTableTextAreaCell({
        propName: "description",
        value: transaction.description,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        disabled: editDisabled,
        inlineEdit: false
      })}
      <td
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Form.Control>

          <Form.Field className="has-addons">
            <AsyncTypeahead
              useCache={false}
              id="typeahead"
              labelKey="display_name"
              minLength={2}
              name={"contact"}
              onSearch={handleSearch}
              onChange={handleTransactionContactSelect}
              placeholder={"Search Contact"}
              options={searchData}
              ref={typeahead => setTypeahead(typeahead)}
              className="typeahead"
              isLoading={isLoading}
              selected={
                transaction.contact && transaction.contact.id ? [transaction.contact] : []
              }
              disabled={!editable}
            />
          </Form.Field>
        </Form.Control>
      </td>
      {/*{useEditableTableTextCell({*/}
      {/*  propName: "type",*/}
      {/*  value: transaction.type,*/}
      {/*  onChange,*/}
      {/*  onSave: safeSave,*/}
      {/*  editable,*/}
      {/*  setEditable,*/}
      {/*  disabled: editDisabled*/}
      {/*})}*/}
      <td
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Form.Control>
          <Form.Field className="has-addons">
            <Form.Control>
              <Form.Select
                onChange={handleCostCentreUpdate}
                name="cost_centre"
                value={transaction.cost_centre ? transaction.cost_centre.id : ''}
                disabled={!editable}
              >
                {costCentres.map(value => (
                  <option key={value.id} value={value.id}>
                    {value.name}
                  </option>
                ))}
                <option value={''}>
                  None
                </option>
              </Form.Select>
            </Form.Control>
          </Form.Field>
          <p className="small-row-black has-text-centered">
            {transaction.supplier && transaction.supplier.transactions_contact_number}
          </p>
        </Form.Control>
      </td>
      <td
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Form.Control>
          <Form.Field className="has-addons">
            <Form.Control>
              <Form.Select
                onChange={handleCategoryUpdate}
                name="type"
                value={transaction.category ? transaction.category.id : transactionCategories[0].id}
                disabled={!editable}
              >
                {categoryFields.map(value => (
                  <option key={value.id} value={value.id}>
                    {value.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Control>
          </Form.Field>
          <p className="small-row-black has-text-centered">
            {transaction.supplier && transaction.supplier.transactions_contact_number}
          </p>
        </Form.Control>
      </td>
      {useEditableTableCostCell({
        propName: "amount",
        value: transaction.amount,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        disabled: editDisabled,
        inlineEdit: false
      })}

      {useEditableTableDateCell({
        propName: "invoice_date",
        value: transaction.invoice_date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        disabled: editDisabled,
        inlineEdit: false
      })}
      {useEditableTableTextCell({
        propName: "invoice_number",
        value: transaction.invoice_number,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        disabled: editDisabled,
        inlineEdit: false
      })}
      {useEditableTableCostCell({
        propName: "invoice_amount",
        value: transaction.invoice_amount,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        disabled: editDisabled,
        inlineEdit: false
      })}
      {useEditableTableCostCell({
        propName: "invoice_vat",
        value: transaction.invoice_vat,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        disabled: editDisabled,
        inlineEdit: false
      })}
      <td>
        <Form.Control>
          <Form.Input
            className="small-row-black"
            type="text"
            name="invoice_total"
            value={
              (parseFloat(1 * transaction.invoice_amount + 1 * transaction.invoice_vat) || 0).toFixed(2)
            }
            disabled={true}
            readonly={true}
          />
        </Form.Control>
      </td>
      {useEditableTableDateCell({
        propName: "payment_date",
        value: transaction.payment_date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        disabled: editDisabled
      })}
      {useEditableTableTextAreaCell({
        propName: "comments",
        value: transaction.comments,
        onChange,
        onSave: safeSave,
        editable,
        setEditable
      })}
      <td>
        <Form.Field>
          <Form.Input
            disabled={true}
            name={"creator"}
            value={transaction.creator ? `${transaction.creator.first_name} ${transaction.creator.last_name}` : ""}
          />
        </Form.Field>
      </td>
      {editDisabled && <td>{transaction.approved && transaction.approver ? `${transaction.approver.first_name} ${transaction.approver.last_name}` : ""}</td>}
      <td>
      {useInlineEditButton({show: true, editable, setEditable, onSave: safeSave})}
      </td>
      <td onClick={e => e.stopPropagation()}>
        <Form.Field>
          <Form.Control className="table-checkbox">
            <Form.Checkbox
              checked={selected}
              type="checkbox"
              onClick={onCheck}
              readOnly
            />
          </Form.Control>
        </Form.Field>
      </td>
      {transaction.approved &&
      <td className="float-right">
        <Button
          color="warning"
          data-testid={`submit-transaction-approve${transaction.id}`}
          onClick={(e) => onTransactionApprove(e, false)}
        >
          <FAIcon size="small" icon={["fas", "times"]}/>
        </Button>
      </td>
      }
      {!transaction.approved &&
      <td className="float-right">
        <Button
          color="success"
          data-testid={`submit-transaction-approve${transaction.id}`}
          onClick={(e) => onTransactionApprove(e, true)}
        >
          <FAIcon size="small" icon={["fas", "check"]}/>
        </Button>
      </td>
      }
      <td className={"is-narrow"}>
        <Button
          color={"danger"}
          onClick={() => {
            setDeleteModalOpen(true);
          }}
        >
          X
        </Button>
      </td>
      <DeleteWarningModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        onDelete={() => handleDelete(transaction.id)}
      />
    </tr>
  );
}

export default TransactionRow;
