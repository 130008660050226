import React, {useState} from "react";
import {Button, Form} from "react-bulma-components";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import FAIcon from "../Icon/FAIcon";
import WorkTypePicker from "../Job/WorkTypePicker";
import {createSetWorkDataAction} from "../../actions/dataActions";




export default function StageChangeRow({work, view, onWorkSave, index, workTypes, workTypeObjects}){
    const [editable, setEditable] = useState(false);
    const [workState, setWorkState] = useState(work);


    const onChange = e => {
        e.stopPropagation();
        let newWork = {...workState};
        newWork[e.target.name] = e.target.value;
        setWorkState(newWork);
    };
    /*
    const onInvoiceNumberChange = e => {
        //Call correct function
        //Call normal onChange to update state
        // view === "po" ? (e) => updateWorkshopInvoiceValue(index, e.target.value) : workState.chargeable ? (e) => updateHireCustomerInvoiceValue(index, e.target.value) : (e) => updateCustomerInvoiceValue(index, e.target.value)
        if(view === 'po'){
            updateWorkshopInvoiceValue(index, e.target.value);
        }
        if(view === 'invoice'){
            if(workState.chargeable){
                updateHireCustomerInvoiceValue(index, e.target.value);
            }
            else {
                updateCustomerInvoiceValue(index, e.target.value);
            }
        }
        onChange(e);
    }
    */

    const onWorkTypeChange = e => {
        e.stopPropagation();
        let newWork = {...workState};
        newWork[e.target.name] = workTypeObjects[e.target.value];
        setWorkState(newWork);
    }

    return (
        <tr
            className="clickable"
            onClick={() => window.open(`/editjob/${work.job}`, "_self")}
            key={workState.id}
        >
            <td>{workState.id}</td>
            <td>{workState.description}</td>
            {useEditableTableTextAreaCell({
              propName: "invoice_comments",
              value: workState.invoice_comments,
              onChange: onChange,
              onSave: () => onWorkSave(workState, setEditable, view),
              editable,
              setEditable,
              inlineEdit: true,
              disabled: view === 'po' ? work.po_approved : work.invoice_approved
            })}
            <td>£{workState.labour_cost}</td>
            {useEditableTableTextCell({
                propName: view ==="po" ? 'job_workshop_invoice_number' : workState.chargeable ? 'job_customer2_invoice_number' : 'job_customer_invoice_number',
                value: view ==="po" ? workState.job_workshop_invoice_number : workState.chargeable ? workState.job_customer2_invoice_number : workState.job_customer_invoice_number,
                onChange: onChange,
                onSave: () => onWorkSave(workState, setEditable, view),
                editable,
                setEditable,
                inlineEdit: true,
                disabled: view === 'po' ? workState.po_approved : workState.invoice_approved

            })}
            {view === "po" &&
                <td onClick={e => e.stopPropagation()}>
                    <Form.Field className={"has-addons"}>
                        <Form.Control>
                            <Form.Input
                                type={"date"}
                                onChange={onChange}
                                name={"job_workshop_payment_date"}
                                value={workState.job_workshop_payment_date}
                                disabled={!editable}
                            />
                        </Form.Control>
                        {editable ? (
                            <Form.Control>
                              <Button color="success" onClick={() => onWorkSave(workState, setEditable, view)}>
                                <FAIcon size="small" icon={["fas", "save"]}></FAIcon>
                              </Button>
                            </Form.Control>
                          ) : (
                            <Form.Control>
                              <Button onClick={()=> setEditable(true)}>
                                  <FAIcon size="small" icon={["fas", "edit"]}/>
                              </Button>
                            </Form.Control>
                        )}
                    </Form.Field>
                </td>
            }
            {view === 'po' &&
                <td onClick={e => e.stopPropagation()}>
                    <WorkTypePicker
                        options={workTypes}
                        work={workState}
                        disabled={!editable}
                        editable={editable}
                        setEditable={setEditable}
                        showTitle={false}
                        onSave={() => onWorkSave(workState, setEditable, view, index)}
                        onSelect={(e) => onWorkTypeChange(e, index)}
                    />
                </td>
            }
        </tr>
    )
}