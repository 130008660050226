import {Box} from "react-bulma-components";
import Spinner from "react-spinner-material";
import React from "react";

export default function LoadingSpinner(){
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
}