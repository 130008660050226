import React, {useState} from "react";
import { Button } from "react-bulma-components";
import {getPartCustomerCost, getPartCustomerEstimatedCost, roundCurrency} from "../../utils";
import { Form } from "react-bulma-components";

import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import {Icon} from "react-bulma-components";
import FAIcon from "../Icon/FAIcon";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";

const AsyncTypeahead = asyncContainer(Typeahead);

function Part({
                part,
                index,
                customer,
                updateParts,
                removePart,
                onSearchPartClick,
                quote,
                invoiced,
                appDefaults
              }) {
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [, setTypeahead] = useState();

  const handleChange = e => {
    const newPart = getNewPart(e);
    updateParts(newPart, index);
  };

  const onSearch = e => {
    onSearchPartClick(part, index);
  };

  const getNewPart = e => {
    let newPart = {...part, [e.target.name]: e.target.value};

    if (e.target.name === "cost") {
      newPart.customer_cost = getPartCustomerCost(newPart, customer, appDefaults);
    }
    if (
      e.target.name === "estimated_cost"
    ) {
      newPart.estimated_cost = e.target.value;
      newPart.estimated_customer_cost = getPartCustomerEstimatedCost(newPart, customer, appDefaults)
    }
    return newPart;
  };

  const handleSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=SP&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  };

  const handlePartSupplierSelect = supplier => {
    let newPart = {...part, supplier: supplier[0]};
    updateParts(newPart, index);
  };

  const onCheck = prop => {
    let newPart = {...part, [prop]: !part[prop]};
    if (prop === "cost_override" && newPart[prop] === false) {
      newPart.customer_cost = getPartCustomerCost(newPart, customer, appDefaults);
      newPart.estimated_customer_cost = getPartCustomerEstimatedCost(newPart, customer, appDefaults)
    }
    updateParts(newPart, index);
  };

  return (
    <tr>
      <td>
        <Form.Field className="has-addons">
          <Form.Control>
            <Form.Input
              type="text"
              value={part.name}
              name="name"
              onChange={handleChange}
              disabled={invoiced}
              data-testid="part-name"
            />
          </Form.Control>
          <Form.Control>
            <Button
              data-testid="search-button"
              color="success"
              onClick={onSearch}
            >
              <FAIcon icon={["fas", "search"]}/>
            </Button>
          </Form.Control>
        </Form.Field>
      </td>
      {quote && (
        <td>
          <Form.Control>
            <Form.Input
              data-testid="est-cost-input"
              className="input"
              type="number"
              value={String(part.estimated_cost)}
              disabled={invoiced}
              name="estimated_cost"
              onChange={handleChange}
            />
            <Icon align={"left"} className={"small-icon"}>
                <FontAwesomeIcon icon={faPoundSign}/>
            </Icon>
          </Form.Control>
        </td>
      )}
      {quote && (
        <td>
          <Form.Control>
            <Form.Input
              type="number"
              name="estimated_customer_cost"
              onChange={handleChange}
              value={String(part.estimated_customer_cost)}
              disabled={!part.cost_override || invoiced}
            />
            <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
          </Form.Control>
        </td>
      )}
      <td>
        <Form.Control>
          <AsyncTypeahead
            useCache={false}
            id="typeahead"
            labelKey="display_name"
            minLength={2}
            name={"supplier"}
            onSearch={handleSearch}
            onChange={handlePartSupplierSelect}
            placeholder={"Search Supplier"}
            options={searchData}
            ref={typeahead => setTypeahead(typeahead)}
            className="typeahead"
            isLoading={isLoading}
            selected={part.supplier && part.supplier.id ? [part.supplier] : []}
            disabled={invoiced}
          />
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            type="date"
            value={part.date_ordered || ""}
            name="date_ordered"
            onChange={handleChange}
            disabled={invoiced}
          />
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            type="text"
            value={part.part_number}
            name="part_number"
            onChange={handleChange}
            disabled={invoiced}
          />
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            type="text"
            value={part.part_invoice_number}
            name="part_invoice_number"
            onChange={handleChange}
            disabled={invoiced}
          />
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            data-testid="cost-input"
            className="input"
            type="number"
            value={String(part.cost)}
            name="cost"
            onChange={handleChange}
            disabled={!!invoiced}
          />
          <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            type="number"
            name="customer_cost"
            onChange={handleChange}
            value={part.customer_cost}
            checked={part.customer_cost}
            disabled={!part.cost_override || invoiced}
          />
          <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
        </Form.Control>
      </td>
      {useEditableTableTextAreaCell(
        {
          propName: "comments",
          value: part.comments,
          onChange: handleChange,
          editable: true,
        }
      )}
      <td>
        <Form.Control>
          <Form.Checkbox
            type="checkbox"
            name="cost_override"
            onChange={() => onCheck("cost_override")}
            checked={part.cost_override}
            disabled={invoiced}
          />
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Checkbox
            type="checkbox"
            name="from_stock"
            onChange={() => onCheck("from_stock")}
            checked={part.from_stock}
            disabled={invoiced}
          />
        </Form.Control>
      </td>
        {useEditableTableDateCell({
            propName: "backorder_expected_date",
            value: part.backorder_expected_date,
            onChange: handleChange,
            editable: true,
            inlineEdit: false
        })}
      <td>
        <Form.Control>
          <Form.Checkbox
            type="checkbox"
            name="to_be_ordered"
            onChange={() => onCheck("to_be_ordered")}
            checked={part.to_be_ordered}
            disabled={invoiced}
          />
        </Form.Control>
      </td>
      <td>
        <Button
          remove
          onClick={() => removePart(index)}
          data-testid="remove-part"
        >
          Remove
        </Button>
      </td>
    </tr>
  );
}

export default Part;
