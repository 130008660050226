import React, { useState } from "react";
import { readableDate } from "../../utils";
import { getDataFresh } from "../../state/selectors";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { createSetDataFreshAction } from "../../actions/dataActions";
import { Button } from "react-bulma-components";
import { NotificationManager } from "react-notifications";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import useSafeSave from "../../hooks/useSafeSave";

export default function InspectionRow({
  inspection,
  onInspectionUpdate,
  endpoint,
  index
}) {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  let dataFresh = useSelector(getDataFresh);
  const [lastEdited, setLastEdited] = useState();

  const onSave = () => {
    const url = endpoint + "inspections/" + inspection.id;
    let inspectionToSave = { ...inspection };
    inspectionToSave.vehicle = inspection.vehicle.id;
    const conf = {
      method: "put",
      data: inspectionToSave,
      url
    };

    return axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const checkUpdated = () => {
    return axios
      .get(endpoint + "inspections/" + inspection.id)
      .then(newInspection => {
        return newInspection.data.last_saved === inspection.last_saved;
      });
  };

  const onInspectionApprove = e => {
    e.stopPropagation();
    if (inspection.new_completed_date && inspection.new_expiry_date) {
      let inspectionToSave = { ...inspection };
      inspectionToSave.vehicle = inspection.vehicle.id;
      const url = endpoint + "inspections/approve/";
      const conf = {
        method: "put",
        data: inspectionToSave,
        url
      };
      axios(conf).then(() => {
        setEditable(false);
        dispatch(createSetDataFreshAction(dataFresh + 1));
      });
    } else {
      NotificationManager.warning(
        "You must specify a new expiry and completion date before appriving!",
        "Inspection information required!",
        10000
      );
    }
  };

  const [makeDirty, saveAndClear] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newInspection = { ...inspection };
    newInspection[e.target.name] = e.target.value;
    onInspectionUpdate(index, newInspection);
  };

  return (
    <tr
      key={inspection.id}
      className="clickable small-row-black"
      onClick={() =>
        window.open(`/editvehicle/${inspection.vehicle.id}`, "_self")
      }
    >
      <td>{inspection.vehicle.fleet_number}</td>
      <td>{inspection.vehicle.registration}</td>
      <td>{inspection.vehicle.location}</td>
      <td> {inspection.inspection_type}</td>
      <td>
        {inspection.inspection_workshop
          ? inspection.inspection_workshop.display_name
          : ""}
      </td>
      <td>{inspection.vehicle.hire_customer ? inspection.vehicle.hire_customer.display_name : ""}</td>
      <td> {readableDate(inspection.expiry_date)}</td>
      <td> {readableDate(inspection.date_completed)}</td>
      {useEditableTableTextAreaCell({
        propName: "comments",
        value: inspection.comments,
        onChange,
        onSave: saveAndClear,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableDateCell({
        propName: "booked_in_date",
        value: inspection.booked_in_date,
        onChange,
        onSave: saveAndClear,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableDateCell({
        propName: "new_completed_date",
        value: inspection.new_completed_date,
        onChange,
        onSave: saveAndClear,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableDateCell({
        propName: "new_expiry_date",
        value: inspection.new_expiry_date,
        onChange,
        onSave: saveAndClear,
        editable,
        setEditable,
        inlineEdit: true
      })}
      <td className="float-right">
        <Button
          color="success"
          data-testid={`submit-inspection-approve${inspection.id}`}
          onClick={onInspectionApprove}
        >
          Approve
        </Button>
      </td>
    </tr>
  );
}
