import React, { useState } from "react";

import InspectionRow from "./InspectionRow.js";

import {Box, Tabs} from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { createSetInspectionDataAction } from "../../actions/dataActions";
import { getDataFresh, getInspectionData } from "../../state/selectors";
import { useInspectionData } from "../../hooks/useInspectionData";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-spinner-material";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components"
import { Button } from "react-bulma-components";
import axios from "axios";
import { formatDate, readableDate } from "../../utils";
import SearchBar from "../Utils/SearchBar";

export default function Inspection(props) {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const dataFresh = useSelector(getDataFresh);
  const [filters, setFilters] = useState({});
  const [searchText, setSearchText] = useState("");
  const [currentSearch, setCurrentSearch] = useState("");
  const [activeTab, setTab] = useState("all");
  const [searchFresh, setSearchFresh] = useState(1);

  let params = { page };

  if (currentSearch) {
    params.search = currentSearch;
  }
  if (searchFresh){
    params.searchFresh = searchFresh;
  }
  if(activeTab){
    params.view = activeTab;
  }
  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const complete = useInspectionData(params, dataFresh);
  const data = useSelector(getInspectionData);

  const onInspectionUpdate = (index, newInspection) => {
    let newState = { ...data };
    newState.results[index] = newInspection;
    dispatch(createSetInspectionDataAction(newState));
  };

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
    setSearchFresh(searchFresh+1);
  };

  const setNewTab = (tab) => {
    setPage(1)
    setTab(tab)
  };
  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };

  const headings = [
    {
      label: "Fleet Number"
    },
    {
      label: "Registration",
      field: "vehicle__registration",
      labelField: "vehicle__registration"
    },
    {
      label: "Vehicle Location",
      field: "vehicle__location",
      labelField: "vehicle__location"
    },
    {
      label: "Type",
      field: "inspection_type",
      labelField: "inspection_type"
    },
    {
      label: "Workshop",
      field: "inspection_workshop",
      labelField: "inspection_workshop__display_name"
    },
    {
      label: "Hire Customer",
      field: "vehicle__hire_customer",
      labelField: "vehicle__hire_customer__display_name"
    },
    {
      label: "Expiry Date",
      field: "expiry_date",
      labelField: "expiry_date",
      date: true
    },
    {
      label: "Last Completed",
      field: "date_completed",
      labelField: "date_completed",
      date: true
    },
    {
      label: "Comments"
    },
    {
      label: "Book In Date",
      field: "booked_in_date",
      labelField: "booked_in_date",
      date: true
    },
    {
      label: "New Completed Date",
      field: "new_completed_date",
      labelField: "new_completed_date",
      date: true
    },
    {
      label: "New Expiry Date",
      field: "new_expiry_date",
      labelField: "new_expiry_date",
      date: true
    }
  ];

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  document.title = "Inspections";

  return (
    <div>
      <Box>
        <h1 className="title">Inspections</h1>
        <Columns>
          <Columns.Column size={4}>
            <Form.Label>Search</Form.Label>
            <SearchBar
              handleSearch={handleSearch}
              setSearchText={setSearchText}
              searchText={searchText}
              clear={clear}
            />
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <h1 className="title">Upcoming Inspections</h1>
        <Tabs type="toggle" align="left">
          <Tabs.Tab active={activeTab === "all"} onClick={() => setNewTab("all")}>
            All
          </Tabs.Tab>
          <Tabs.Tab active={activeTab === "fleet"} onClick={() => setNewTab("fleet")}>
            Fleet
          </Tabs.Tab>
          <Tabs.Tab active={activeTab === "customer"} onClick={() => setNewTab("customer")}>
            Customer
          </Tabs.Tab>
        </Tabs>
        <table className="table is-hoverable is-fullwidth is-striped">
          <thead>
            <tr>
              {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.results.map((value, index) => (
              <InspectionRow
                key={value.id}
                inspection={value}
                index={index}
                onInspectionUpdate={onInspectionUpdate}
                endpoint={props.endpoint}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          showFirstLast={true}
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 25)}
        />
      </Box>
    </div>
  );
}
