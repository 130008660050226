import FilterHeading from "../../../Invoicing/FilterHeading";
import React from "react";
import FilterBlankHeading from "../../../Invoicing/FilterBlankHeading";
import {act} from "react-dom/test-utils";

function ForSaleHeadings({data, activeTab, vehicleStatus, filters, setPage, setFilters}) {
    const headings = [
      {
          label:"Registration",
          display: true
      },
      {
        label:"Fleet Number",
        display: activeTab === "fleet"
      },
      {
        label: "Owner",
        field: "owner",
        labelField: "owner__display_name",
        display:activeTab === "customer"
      },
      {
          label:"Make",
          field:"make",
          labelField:"make__name",
          display: true
      },
      {
          label:"Model",
          field:"model",
          labelField:"model__name",
          display: true
      },
      {
          label: "Variant",
          display: true
      },
      {
          label:"Chassis No.",
          display:true
      },
      {
          label: "Tracker IMEI",
          display: true
      },
      {
        label:"Type",
        field:"type",
        labelField: "type__name",
        display:true
      },
      {
          label:"Comments",
          display:true
      },
      {
        label:"Sold To",
        display:true
      },
      {
        label:"Sold Date",
        display:true
      },
      {
        label:"Sold Price",
        display:true
      },
      {
        label:"Remaining Capital",
        display:true
      },
      {
        label:"Depreciated Value",
        display:true
      },
      {
        label:"Advertised Price",
        display:true
      }
  ]
  return (
      <tr>
          {headings.map(heading => {
              if(heading.display) {
                  if (!heading.field) {
                        return <th key={heading.label}>{heading.label}</th>;
                  }
                  if (heading.blank) {
                      return (
                          <FilterBlankHeading
                              key={heading.label}
                              heading={heading}
                              setFilters={f => {
                                  setPage(1);
                                  setFilters(f);
                              }}
                              filters={filters}
                          />
                      );
                  }
                  return (
                      <FilterHeading
                          key={heading.label}
                          heading={heading}
                          setFilters={f => {
                              setPage(1);
                              setFilters(f);
                          }}
                          filters={filters}
                          options={data.filter_list[heading.field]}
                      />
                  );
              }
          })}
      </tr>
  )
}

export default ForSaleHeadings;
