import React, { useState } from "react";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Table } from "react-bulma-components";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh, getWorkLookupData, getWorksData, getVehicleTypesData} from "../../state/selectors";
import { Pagination } from "react-bulma-components";
import WorkLookupRow from "./WorkLookupRow";
import useSelect from "../../hooks/useSelect";
import {
  createSetWorkLookupDataAction,
  createSetWorksDataAction,
  createSetWorkDataAction
} from "../../actions/dataActions";
import {useWorkLookupsData} from "../../hooks/useWorkLookupsData";
import CreateWorkModal from "./CreateWorkModal";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import {useVehicleTypeData} from "../../hooks/useVehicleTypeData";
import SearchBar from "../Utils/SearchBar";

function WorkLookup(props) {
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const [createWorkModalOpen, setCreateWorkModalOpen] = useState(false);

  const headings = [
    {
      label: "Description",
    },
    {
      label: "Labour Hours",
    },
    {
      label: "Labour Cost",
    },
    {
      label: "Customer Labour Charge"
    },
    {
      label: "Supplier",
      field: "supplier",
      labelField: "supplier__display_name"
    },
    {
      label: "Number of Parts"
    }
  ];

  let params = {
    page,
    search,
  };

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const complete = useWorkLookupsData(params, dataFresh);
  const data = useSelector(getWorkLookupData);

  const updateValue = (index, newWork) => {
    let currentData = { ...data };
    currentData.results[index] = newWork;
    dispatch(createSetWorkLookupDataAction(currentData));
  };

  if (!complete) return <div>Loading</div>;

  document.title = "Work Stock";

  const clear = e => {
    setSearch("");
    setSearchText("");
  };
  const handleSearch = e => {
    setPage(1);
    setSearch(searchText);
  }

  return (
    <div>
      <Box>
        <Columns>
          <Columns.Column>
            <Form.Label>Work Search</Form.Label>
            <SearchBar
              handleSearch={handleSearch}
              clear={clear}
              setSearchText={setSearchText}
              searchText={searchText}
              inputTestId={"work-search"}
              searchButtonTestId={"search-button"}
            />
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Control>
                <Button
                  onClick={() => setCreateWorkModalOpen(true)}
                  color="success"
                  className="is-pulled-right"
                >
                  New Work +
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Table size={"fullwidth"}>
          <thead>
            <tr>
              {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
              })}
              {/*<th>Make</th>*/}
              {/*<th>Model</th>*/}
              {/*<th>Type</th>*/}
              {/*<th>Work Name</th>*/}
              {/*<th>Work Number</th>*/}
              {/*<th>Work Cost</th>*/}
              {/*<th>Supplier</th>*/}
              {/*<th>Stock Volume</th>*/}
              {/*<th>Comments</th>*/}
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((work, index) => (
              <WorkLookupRow
                key={work.id}
                workLookup={work}
                endpoint={props.endpoint}
                updateValue={newWork => updateValue(index, newWork)}
              />
            ))}
          </tbody>
        </Table>
        <Pagination
          showFirstLast={true}
          onChange={setPage}
          current={page}
          total={Math.ceil(data.count / 25)}
        ></Pagination>
      </Box>
      <CreateWorkModal
        open={createWorkModalOpen}
        setOpen={setCreateWorkModalOpen}
        endpoint={props.endpoint}
      />
    </div>
  );
}

export default WorkLookup;
