import React from "react";
import {Columns, Form, Icon} from "react-bulma-components";
import {NotificationManager} from "react-notifications";
import {formatCurrency, roundCurrency} from "../../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";


export default function NegativeFinanceSection({financeAgreement, onChange, index}){
    // Change depreciation formula if finance_type = NEGATIVE_FINANCE

    return (
        <div>
            <Columns>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Amount</Form.Label>
                        <Form.Control>
                            <Form.Input
                                type={"number"}
                                name={"finance_amount_borrowed"}
                                value={financeAgreement.finance_amount_borrowed}
                                onChange={onChange}
                            />
                            <Icon align={"left"} className={"small-icon"}>
                                <FontAwesomeIcon icon={faPoundSign}/>
                            </Icon>
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
            </Columns>
        </div>
    )


}