import React, {useState} from "react";
import {Columns, Modal, Section, Form, Table, Heading, Button} from "react-bulma-components";
import {useDispatch} from "react-redux";
import StageChangeRow from "./StageChangeRow";
import {NotificationManager} from "react-notifications";
import axios from "axios";
import {createSetDataFreshAction, createSetWorkDataAction} from "../../actions/dataActions";


export default function StateChangeModal({selectedWork, multi=false, setMulti, getCurrentState, setSelected, dataFresh, filteredWork, setFilteredWork, currentFilter, view, open, setOpen, endpoint, onWorkSave, onStageChange, workTypeObjects, workTypes}){
    let currentState;
    if(multi){
        currentState = getCurrentState(filteredWork.results[0]);
    }
    else{
        currentState = getCurrentState(selectedWork);
    }

    return (
        <Modal
            closeOnBlur
            show={open}
            onClose={() => {
                setFilteredWork({results: []});
                setSelected({})
                setMulti(false);
                setOpen(false);
            }}
        >
            <Modal.Content className={"wider-modal"}>
                <Section style={{ backgroundColor: "white" }}>
                    <Heading>All related work</Heading>
                    {!multi &&
                        <Columns>
                            <Columns.Column>
                                <Form.Field>
                                    <Form.Control>
                                        <Form.Label>Registration</Form.Label>
                                        <Form.Input
                                            type={"text"}
                                            value={selectedWork ? selectedWork.vehicle_registration : ""}
                                            disabled={true}
                                        />
                                    </Form.Control>
                                </Form.Field>
                            </Columns.Column>
                            <Columns.Column>
                                <Form.Field>
                                    <Form.Control>
                                        <Form.Label>Customer</Form.Label>
                                        <Form.Input
                                            type={"text"}
                                            value={selectedWork ? selectedWork.customer : ""}
                                            disabled={true}
                                        />
                                    </Form.Control>
                                </Form.Field>
                            </Columns.Column>
                            <Columns.Column>
                                <Form.Field>
                                    <Form.Control>
                                        <Form.Label>Workshop</Form.Label>
                                        <Form.Input
                                            type={"text"}
                                            value={selectedWork ? selectedWork.workshop : ""}
                                            disabled={true}
                                        />
                                    </Form.Control>
                                </Form.Field>
                            </Columns.Column>
                            <Columns.Column>
                                <Form.Field>
                                    <Form.Control>
                                        <Form.Label>Job Id</Form.Label>
                                        <Form.Input
                                            type={"number"}
                                            value={selectedWork ? selectedWork.job : ""}
                                            disabled={true}
                                        />
                                    </Form.Control>
                                </Form.Field>
                            </Columns.Column>
                        </Columns>
                    }
                    <Columns>
                        <Columns.Column>
                            <Table
                                size={"fullwidth"}
                            >
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Description</th>
                                        <th>Comments</th>
                                        <th>Labour Cost (£)</th>
                                        <th>{view === 'po' ? 'Workshop Invoice Number' : 'Customer Invoice Number'}</th>
                                        {view === 'po' && <th>Payment Date</th>}
                                        {view === 'po' && <th>Work Type</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {((filteredWork && filteredWork.results) ?? []).map((work, index) => (
                                        <StageChangeRow
                                            onWorkSave={onWorkSave}
                                            work={work}
                                            view={view}
                                            key={work.id}
                                            index={index}
                                            workTypeObjects={workTypeObjects}
                                            workTypes={workTypes}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Button
                                fullwidth
                                color={"success"}
                                onClick={onStageChange}
                            >
                                {view === "po" ? "Approve" : view === "invoice" ? currentState === "ready_to_approve" ? "Approve" : "Ready To Invoice" : ""}
                            </Button>
                        </Columns.Column>
                        <Columns.Column>
                            <Button
                                fullwidth
                                color={"info"}
                                onClick={() => {
                                    setFilteredWork({results: []});
                                    setMulti(false);
                                    setSelected({});
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Columns.Column>
                    </Columns>
                </Section>
            </Modal.Content>
        </Modal>
    )


}