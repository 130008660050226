import React, {useState} from "react";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../state/selectors";
import { Button } from "react-bulma-components";
import useSafeSave from "../../hooks/useSafeSave";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import DeleteWarningModal from "../Modal/DeleteWarningModal";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import { Form } from "react-bulma-components";
import FAIcon from "../Icon/FAIcon";
import VehicleTrackerTypePicker from "./TrackerTypePicker";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import {fixDates} from "../../utils";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function VehicleTrackerRow({tracker, endpoint, updateValue, trackerTypes, trackerTypeObjects}) {
  let dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [, setTypeahead] = useState();
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [lastEdited, setLastEdited] = useState();


  const onSave = e => {
    let toSave = fixDates(tracker, ["date_assigned", "date_activated", "date_deactivated"])
    const url = endpoint + "vehicletracker/" + tracker.id;
    const conf = {
      method: "put",
      data: toSave,
      url
    };
    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
      setEditable(false);
    });
  };



  const checkUpdated = () => {
    return axios
      .get(endpoint + "vehicletracker/" + tracker.id)
      .then(
        response => true
      );
  };

  const [makeDirty, safeSave] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newTracker = {...tracker};
    newTracker[e.target.name] = e.target.value;
    updateValue(newTracker);
  };

  const handleSearch = searchTerm => {
    setLoading(true);
    axios.get(`/api/vehicles?search=${searchTerm}&tracker=BLANK`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  }

  const handleVehicleSelect = results => {
    if(results && results.length) {
      let newTracker = {...tracker}
      newTracker.vehicle = results[0];
      updateValue(newTracker);
    } else {
      updateValue({
        ...tracker,
        vehicle: null
      })
    }
  };

  const saveAfterButtonClick = () => {
    const url = endpoint + "vehicletracker/" + tracker.id;
    const conf = {
      method: "put",
      data: tracker,
      url
    };

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const buttonClick = prop => {
    tracker[prop] = !tracker[prop];
    saveAfterButtonClick();
  };
  const onTypeSelect = (e) => {
      let newTracker = {...tracker}
      if(e.target.value !== 'BLANK'){
          newTracker['type'] = trackerTypeObjects[e.target.value];
      }
      else{
          newTracker['type'] = null;
      }
      updateValue(newTracker);
  }
  return (
    <tr key={tracker.id}>
      {useEditableTableTextCell({
          propName: "imei",
          value: tracker.imei,
          onChange: onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        }
      )}
      <td>
        <Form.Field className="has-addons">
          <Form.Control>
            <AsyncTypeahead
                useCache={false}
                id="typeahead"
                labelKey="registration"
                minLength={2}
                name={"vehicle"}
                onSearch={handleSearch}
                onChange={handleVehicleSelect}
                placeholder={"Search Vehicle Registration"}
                disabled={!editable}
                options={searchData}
                ref={typeahead => setTypeahead(typeahead)}
                className="typeahead"
                isLoading={isLoading}
                selected={tracker.vehicle && tracker.vehicle.id ? [tracker.vehicle] : []}
            />
          </Form.Control>
          {editable ? (
              <Form.Control>
                <Button
                    className="small-row-white"
                    color="success"
                    onClick={onSave}
                >
                  <FAIcon size="small" icon={["fas", "save"]}></FAIcon>
                </Button>
              </Form.Control>
          ) : (
              <Form.Control>
                <Button
                    className="small-row-black"
                    onClick={() => setEditable(!editable)}
                >
                  <FAIcon size="small" icon={["fas", "edit"]}/>
                </Button>
              </Form.Control>
          )}
        </Form.Field>
      </td>
      <td>
        <VehicleTrackerTypePicker
          types={trackerTypes}
          updateValue={onTypeSelect}
          dataFresh={dataFresh}
          tracker={tracker}
          onSave={onSave}
          editable={editable}
          setEditable={setEditable}
        />
      </td>
      {useEditableTableDateCell({
          propName: "date_assigned",
          value: tracker.date_assigned,
          onChange: onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true,
          monthOnly: false
        }
      )}
      {useEditableTableDateCell({
          propName: "date_activated",
          value: tracker.date_activated,
          onChange: onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true,
          monthOnly: false
        }
      )}
      {useEditableTableDateCell({
          propName: "date_deactivated",
          value: tracker.date_deactivated,
          onChange: onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true,
          monthOnly: false
        }
      )}
    </tr>
  );
}
