import React from "react";
import {Button, Form} from "react-bulma-components";


export default function SearchBar({handleSearch, searchText, setSearchText, clear, inputTestId="", searchButtonTestId=""}){

    return (
        <Form.Field className="has-addons">
              <Form.Control>
                <Form.Input
                  onChange={e => setSearchText(e.target.value)}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && handleSearch()}
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  data-testid={inputTestId}
                ></Form.Input>
              </Form.Control>
              <Form.Control>
                <Button onClick={handleSearch} type="primary" data-testid={searchButtonTestId}>
                  Search
                </Button>
              </Form.Control>
              <Form.Control>
                <Button onClick={clear} color="warning">
                  Clear
                </Button>
              </Form.Control>
            </Form.Field>
    )
}