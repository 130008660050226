import React from "react";
import {Box, Columns, Heading} from "react-bulma-components";
import Figure from "../../Utils/Figure";


export default function StatusCount({data}){

    return (
        <Box>
           <Columns>
               <Columns.Column>
                   <Heading size={4}>Vehicle Count</Heading>
               </Columns.Column>
           </Columns>
           <Columns>
               <Figure
                  value={data.active}
                  label={"ACTIVE"}
                  round={false}
                  loaded={true}
               />
               <Figure
                  value={data.for_sale}
                  label={"FOR SALE"}
                  round={false}
                  loaded={true}
               />
               <Figure
                  value={data.sold}
                  label={"SOLD"}
                  round={false}
                  loaded={true}
               />
               <Figure
                  value={data.customer}
                  label={"CUSTOMER"}
                  round={false}
                  loaded={true}
               />
               <Figure
                  value={data.ordered}
                  label={"ORDERED"}
                  round={false}
                  loaded={true}
               />
           </Columns>
        </Box>
    )
}