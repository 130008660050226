import React, {useMemo, useRef, useState} from "react";
import { Form } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Button } from "react-bulma-components";
import ServiceRecord from "./ServiceRecord/ServiceRecord";
import Inspection from "./Inspection";
import VehicleRates from "./VehicleRates";
import VehicleInfoSection from "./VehicleInfoSection";
import CustomerOwnerSection from "./CustomerOwnerSection";
import { Icon } from "react-bulma-components";
import {NotificationManager} from "react-notifications";

import axios from "axios";
import shortid from "shortid";
import VehicleRentalRecord from "./VehicleRentalRecord";

import Collapsible from "react-collapsible";
import FeaturesSection from "./FeaturesSection";
import {
  getAppDefaultsData,
  getDataFresh,
  getInspectionTypesData, getKeysData,
  getVehicleData, getVehicleMakeData, getVehicleModelData,
  getVehicleTypesData
} from "../../state/selectors";
import {useDispatch, useSelector} from "react-redux";
import {
  createSetDataFreshAction, createSetVehicleDataAction,
  createSetVehicleFieldAction
} from "../../actions/dataActions";
import DeleteWarningModal from "../Modal/DeleteWarningModal";
import OrderingInfoSection from "./OrderingInfoSection";
import FinanceSection from "./FinanceSection";
import CAPSection from "./CAPSection";
import { Notification } from "react-bulma-components";
import StatusChangeModal from "./StatusChangeModal";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Warranty from "./Warranty";
import SalesPurchaseForm from "./SalesPurchaseForm";
import ReactToPrint from "react-to-print";

function VehicleForm(props) {
  const dateFields = [
    "tax_expiry",
    "date_sold",
    "end_date",
    "finance_start_date",
    "depreciation_start_date",
    "sold_date",
    "internal_sale_date",
    "mileage_last_updated",
    "finance_early_settlement_date",
    "delivery_date",
    "sp_start_date",
    "cap_date",
    "deposit_allocated_date",
    "ordered_date",
    "manufacturer_main_warranty_date",
    "driveline_warranty_date",
    "aftermarket_warranty_date"
  ];

  const financeDateFields = [
    "date_sold",
    "end_date",
    "finance_start_date",
    "depreciation_start_date",
    "sold_date",
    "internal_sale_date",
    "finance_early_settlement_date",
    "sp_start_date",
    "sp_first_payment_date"
  ];
  const printSalesPurchaseForm = useRef();
  const dispatch = useDispatch();
  let vehicle = useSelector(getVehicleData);
  const vehicleMake = useSelector(getVehicleMakeData);
  let vehicleModels = useSelector(getVehicleModelData);
  let inspection_types = useSelector(getInspectionTypesData);
  let vehicleTypes = useSelector(getVehicleTypesData);
  let appDefaults = useSelector(getAppDefaultsData);
  let keys = useSelector(getKeysData);
  let dataFresh = useSelector(getDataFresh);

  const [modalOpen, setModalOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState({
    modalOpen: false,
    new_status: ""
  });
  // Flags a warning and prevents saving if:
  // - At least one finance agreement has "modification" ticked
  // - Some but not all of the finance agreements have a sold date / sold price
  /*
  const modificationWarning = useMemo(() => {
    const financeAgreements = vehicle.finance_agreements;
    const modificationTicked = financeAgreements.find(financeAgreement => financeAgreement.modification === true);
    const saleCount = financeAgreements.filter(financeAgreement => financeAgreement.sold_date || financeAgreement.sold_price).length;

    if(modificationTicked && saleCount > 0 && saleCount < financeAgreements.length) {
      return true;
    }

    return false;
  }, [vehicle])
  */


  const addInspection = e => {
    dispatch(
      createSetVehicleFieldAction({
        inspections: vehicle.inspections.concat({
          key: shortid.generate(),
          expiry_date: "",
          date_completed: "",
          inspection_type: inspection_types.results[0].inspection_type || "",
          vehicle: vehicle.id || undefined,
          inspection_workshop: {}
        })
      })
    );
  };

  const updateInspections = (inspection, index) => {
    let newInspections = [...vehicle.inspections];
    newInspections.splice(index, 1, inspection);
    dispatch(createSetVehicleFieldAction({inspections: newInspections}));
  };

  const removeInspection = index => {
    let newInspections = [...vehicle.inspections];
    newInspections.splice(index, 1);
    dispatch(createSetVehicleFieldAction({inspections: newInspections}));
  };

  const addFinanceAgreement = e => {
    dispatch(
      createSetVehicleFieldAction({
        finance_agreements: vehicle.finance_agreements.concat({
          vehicle: vehicle.id || undefined,
          purchase_price: 0,
          monthly_payment: 0,
          end_date: "",
          depreciation_start_date: "",
          finance_company: "",
          agreement_no: "",
          finance_early: "",
          deposit_amount: 0,
          hp_interest_rate: 0,
          sp_monthly_interest_rate: 0,
          payment_term: 0,
          finance_early_settlement_amount_paid: 0,
          finance_amount_borrowed: 0,
          finance_start_date: "",
          finance_type: "NONE",
          finance_comments: "",
          balloon_payment: 0,
          sp_start_date: "",
          annual_depreciation_rate: appDefaults.default_depreciation ? appDefaults.default_depreciation : 0
        })
      })
    );
  };

  const updateFinanceAgreements = (agreement, index) => {
    let newFinanceAgreements = [...vehicle.finance_agreements];
    newFinanceAgreements.splice(index, 1, agreement);
    dispatch(createSetVehicleFieldAction({finance_agreements: newFinanceAgreements}));
  };

  const removeFinanceAgreement = index => {
    let newFinanceAgreements = [...vehicle.finance_agreements];
    newFinanceAgreements.splice(index, 1);
    dispatch(createSetVehicleFieldAction({finance_agreements: newFinanceAgreements}));
  };

  const handleChange = e => {
    const {name, value} = e.target;
    dispatch(createSetVehicleFieldAction({[name]: value}));
  };

  const handleCheckbox = e => {
    const {name, checked} = e.target;
    dispatch(createSetVehicleFieldAction({[name]: checked}));
  };

  const handleUpdateStatus = new_status => {
    dispatch(createSetVehicleFieldAction({vehicle_status: new_status}));
  };
  const changeStatus = newStatus => {
    setStatusModalOpen({modalOpen: true, new_status: newStatus});
  }
  const fixEmptyDates = vehicle => {
    dateFields.forEach(function (date) {
      if (vehicle[date] === "") {
        vehicle[date] = null;
      }
    });

    vehicle.inspections.forEach(function (inspection) {
      if (inspection.date_completed === "") {
        inspection.date_completed = null;
      }
      if (inspection.start_date === "") {
        inspection.start_date = null;
      }
    });
    vehicle.finance_agreements.forEach(inspection => {
      financeDateFields.forEach((dateField) => {
        if (inspection[dateField] === "") {
          inspection[dateField] = null;
        }
      })
    });
    return vehicle;
  };

  const fixEmptyType = vehicle => {
    if (!vehicle.type) {
      vehicle.type = vehicleTypes.results[0];
    }
    return vehicle;
  };

  const handleDelete = e => {
    e.preventDefault();
    const endpoint = props.endpoint + "vehicles/" + vehicle.id;
    const conf = {method: "delete", url: endpoint};
    axios(conf).then(response => props.history.push("/vehicles"));
  };

  const validator = vehicle => {
    let validSettlements = 0;
    let validHires = 0;
    let hireCount = 0;
    if (vehicle.vehicle_status === 'CUSTOMER' && vehicle.fleet_number && vehicle.fleet_number.length) {
      NotificationManager.warning("External Vehicles should not have a fleet number!", "Warning!", 1000);
      return false;
    }
    //If the vehicle has neither a settlement date or figure, or has both
    let non_hire_finance_agreements = vehicle.finance_agreements.filter(x => !x.finance_type == 'HIRE_AGREEMENT' )
    non_hire_finance_agreements.forEach((agreement) => {
      if ((agreement.finance_early_settlement_date == null && agreement.finance_early_settlement_amount_paid == 0)
          || (agreement.finance_early_settlement_date != null && agreement.finance_early_settlement_amount_paid != 0)){
        validSettlements += 1;
      }
    });
    //If all finance agreements are not valid
    if(!(validSettlements == non_hire_finance_agreements.length)){
      NotificationManager.warning("Vehicles must have a settlement figure and date!", "Warning!", 1000);
      return false;
    }

    vehicle.finance_agreements.forEach(agreement => {
      if (agreement.finance_type === 'HIRE_AGREEMENT'){
        hireCount += 1;
        if (agreement.hire_start_date && agreement.mileage_allowance > 0 && agreement.on_hire_mileage){
          validHires += 1;
        }
      }
    });

    if (!(validHires === hireCount)){
      NotificationManager.warning("Start Date, On Hire Mileage and Mileage Allowance are required");
      return false;
    }

    return true;
  };
  const handleSubmit = e => {
    e.preventDefault();
    vehicle = fixEmptyDates(vehicle);
    vehicle = fixEmptyType(vehicle);
    const endpoint = vehicle.id
      ? props.endpoint + "vehicles/" + vehicle.id
      : props.endpoint + "createvehicle";
    const method = vehicle.id ? "put" : "post";

    if (
      (!vehicle.annual_depreciation_rate &&
        vehicle.annual_depreciation_rate !== 0) ||
      vehicle.annual_depreciation_rate > 100
    ) {
      vehicle.annual_depreciation_rate = appDefaults.default_depreciation;
    }

    if (!validator(vehicle)){
      return;
    }

    const conf = {
      method: method,
      data: vehicle,
      url: endpoint
    };

    axios(conf)
      .then(response => {
        if (response) {
          if (vehicle.id) {
            dispatch(createSetDataFreshAction(dataFresh + 1));
          } else {
            props.history.push("/editvehicle/" + response.data.id);
          }
        }
      })
      .catch(err => {
        if (err.response.data) {
          NotificationManager.error(
            JSON.stringify(err.response.data),
            "Error!",
            10000
          );
        }
      });
  };

  const onKeyClear = (spare) => {
      const url = "/api/vehicles/clearkey";
      let conf = {
          data: {'vehicle': vehicle, 'spare': spare},
          url: url,
          method: "put"
      };
      return axios(conf)
          .then((res) => {
            dispatch(createSetDataFreshAction(dataFresh +1))
          }).catch(err => {
            NotificationManager.error("There was an error\n"+err)
          });
  };

  if (props.loaded) {
    document.title = "Vehicle - " + (vehicle.registration ? vehicle.registration : "New");
    return (
      <div>
        <VehicleInfoSection
          onChange={handleChange}
          handleCheckbox={handleCheckbox}
          handleStatus={changeStatus}
          data={vehicle}
          vehicleTypes={vehicleTypes.results}
          endpoint={props.endpoint}
          vehicleMake={vehicleMake}
          vehicleModel={vehicleModels}
        />
        <CAPSection
          data={vehicle}
          onChange={handleChange}
          />
        <OrderingInfoSection
          data={vehicle}
          onChange={handleChange}
          handleCheckbox={handleCheckbox}
        />
        {(vehicle.vehicle_status === "CUSTOMER" || vehicle.vehicle_status === "SOLD") &&
        <CustomerOwnerSection
          data={vehicle}
          handleStatus={handleUpdateStatus}
          onChange={handleChange}
          handleCheckbox={handleCheckbox}
          endpoint={props.endpoint}
        />
        }
        <Warranty
            vehicle={vehicle}
            endpoint={props.endpoint}
            handleChange={handleChange}
        />
        <Box>
          <Collapsible
            className="title"
            triggerOpenedClassName="title"
            trigger="Tax, Servicing and Insurance"
          >
            <Columns className="columns">
              <Columns.Column>
                <Form.Field>
                  <label className="label">Tax Expiry</label>
                  <div className="control">
                    <input
                      className="input"
                      type="date"
                      name="tax_expiry"
                      onChange={handleChange}
                      value={vehicle.tax_expiry || ""}
                    />
                  </div>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Tax Cost</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="tax_cost"
                      onChange={handleChange}
                      value={vehicle.tax_cost}
                    />
                    <Icon align={"left"} className={"small-icon"} >
                      <FontAwesomeIcon icon={faPoundSign} color={"dimgray"}  />
                    </Icon>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">Service Interval</label>
                  <div className="control">
                    <input
                      className="input"
                      type="number"
                      name="service_interval"
                      onChange={handleChange}
                      value={vehicle.service_interval}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">QBE Insurance</label>
                  <input
                    className="checkbox"
                    type="checkbox"
                    name="qbe_ins"
                    onChange={handleCheckbox}
                    checked={vehicle.qbe_ins}
                  />
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">Daily Insurance</label>
                  <input
                    className="checkbox"
                    type="checkbox"
                    name="daily_ins"
                    onChange={handleCheckbox}
                    checked={vehicle.daily_ins}
                  />
                </div>
              </div>
            </div>
          </Collapsible>
        </Box>
        <FeaturesSection
          handleCheckbox={handleCheckbox}
          onChange={handleChange}
          data={vehicle}
          keys={keys}
          onKeyClear={onKeyClear}
        />
        <Box>
          <Collapsible
            className="title"
            triggerOpenedClassName="title"
            trigger="Purchases, Finance Agreements & Sale Details"
          >
            <Columns>
              <Columns.Column>
                {vehicle.finance_agreements.map((value, index) => (
                  <FinanceSection
                    vehicle={vehicle}
                    financeAgreement={value}
                    key={value.id || value.key}
                    index={index}
                    updateFinanceAgreements={updateFinanceAgreements}
                    removeFinanceAgreement={removeFinanceAgreement}
                    endpoint={props.endpoint}
                    defaultDepreciation={appDefaults.default_depreciation}
                  />
                ))}
                <Button
                  id="add-inspection"
                  color="info"
                  onClick={addFinanceAgreement}
                >
                  Add Purchase/Finance Agreement/Sale Section
                </Button>
              </Columns.Column>
            </Columns>
          </Collapsible>
        </Box>
        <VehicleRates vehicle={vehicle} onChange={handleChange}/>
        <Box>
          <Collapsible
            className="title"
            triggerOpenedClassName="title"
            trigger="Inspections"
          >
            <Form.Label>Note: Greyed out inspections are currently booked in and must be updated and approved from the
              Inspections tab before they can be modified here.</Form.Label>
              <Form.Field>
                <Form.Label>Continue Maintenance After Sale (Tick this box to receive Inspection notifications even if the
                vehicle is sold/customer owned</Form.Label>
                <input
                  className="checkbox"
                  type="checkbox"
                  name="continue_maintenance"
                  onChange={handleCheckbox}
                  checked={vehicle.continue_maintenance}
                />
              </Form.Field>
            <Columns>
              <Columns.Column>
                {vehicle.inspections.map((value, index) => (
                  <Inspection
                    vehicle={vehicle.id}
                    types={inspection_types.results}
                    key={value.id || value.key}
                    index={index}
                    inspection={value}
                    update_inspection={updateInspections}
                    remove_inspection={removeInspection}
                    endpoint={props.endpoint}
                  />
                ))}
                <Button
                  id="add-inspection"
                  color="info"
                  onClick={addInspection}
                >
                  Add Inspection
                </Button>
              </Columns.Column>
            </Columns>
          </Collapsible>
        </Box>
        {vehicle.id && (
          <ServiceRecord
            vehicle={vehicle.id}
            registration={vehicle.registration}
          />
        )}
        {vehicle.id && <VehicleRentalRecord vehicle={vehicle.id}/>}

        {/*modificationWarning &&
          <Notification
            color="danger">
            Warning: Vehicle has at least one modification finance agreement, and not all finance agreements have a sold date / price!
          </Notification>
        */}

        <Box>
          <Form.Field>
            <Form.Control>
              <Button
                onClick={handleSubmit}
                type="submit"
                fullwidth
                color="success"
              >
                Save Vehicle
              </Button>
            </Form.Control>
          </Form.Field>
          <Form.Field>
          <ReactToPrint
                trigger={() => (
                  <Button color="warning" fullwidth>
                    Print Sale and Purchase Form
                  </Button>
                )}
                content={() => printSalesPurchaseForm.current}
                bodyClass="print-portrait"
                pageStyle="@page {
                  size: A4 portrait;
                }"
          />
          </Form.Field>
          {vehicle.id && (
            <Form.Field>
              <Form.Control>
                <Button
                  id="delete-vehicle-button"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  type="submit"
                  fullwidth
                  color="danger"
                >
                  Delete Vehicle
                </Button>
              </Form.Control>
            </Form.Field>
          )}
        </Box>
        <StatusChangeModal
          open={statusModalOpen.modalOpen}
          setOpen={setStatusModalOpen}
          onApprove={handleUpdateStatus}
          new_status={statusModalOpen.new_status}
        />
        <DeleteWarningModal
          open={modalOpen}
          setOpen={setModalOpen}
          onDelete={handleDelete}
        />
        <div style={{display: "none"}}>
          <div>
            <SalesPurchaseForm data={vehicle} ref={printSalesPurchaseForm}/>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
}

export default VehicleForm;
